"use client";

import { useEffect } from "react";

import { useTranslations } from "next-intl";

import { Button } from "@sikt/sds-button";
import "@sikt/sds-button/dist/index.css";

import { useRouter } from "@/src/intl/navigation";
import style from "@/src/styles/error.module.css";

const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  const t = useTranslations("error");

  return (
    <div className={style.errorContainer}>
      <h1 className={style.errorName}>{error.name}</h1>
      <h2 className={style.errorDescription}>{error.message}</h2>
      <Button variant="strong" className={style.retryButton} onClick={reset}>
        {t("try-again")}
      </Button>
    </div>
  );
};

export const ErrorTryRefresh = ({ error }: { error: Error }) => {
  const router = useRouter();

  const reset = () => {
    router.refresh();
  };

  return <Error error={error} reset={reset} />;
};

export default Error;
